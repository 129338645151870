import { getEnv, getQueryClient } from 'common-nextjs';
import { addHours } from 'date-fns';
import { sha1 } from 'js-sha1';
import { fetchUserConfiguration } from '~/api/user';
import { minutes5inMS } from '~/constants/times';
import { getKnownCookie, setKnownCookie } from '~/hooks/cookies';
import fireGAEvent from '~/services/analytics/fireGAEvent';
import fireImpactEvent from '~/services/analytics/fireImpactEvent';
import identifySentryUser from '~/services/sentry/identifyUser';
import { getSentry } from '~/services/sentry/wrapper';

export const trackingIdentifyAnonymous = () => {
  fireImpactEvent('identify', {
    customerId: '',
    customerEmail: '',
  });
};

export async function trackingIdentify(user: {
  username: string;
  external_id: string;
  email?: string;
}) {
  if (!user) {
    return;
  }

  if (getEnv() !== 'production') {
    console.log('Analytics identified', user);
  }

  if (window.amplitude) {
    window.amplitude.getInstance().setUserId(user.external_id);
    window.amplitude
      .getInstance()
      .setUserProperties({ username: user.username });
  }

  fireImpactEvent('identify', {
    customerEmail: user.email ? sha1(user.email) : '',
    customerId: user.external_id || '',
  });

  fireGAEvent('set', 'userId', user.external_id);

  if (window.hj) {
    try {
      const queryClient = getQueryClient();
      const userConfiguration = await queryClient.fetchQuery(
        ['user-configuration'],
        fetchUserConfiguration,
        {
          staleTime: minutes5inMS,
        },
      );
      window.hj('identify', user.external_id, {
        'Purchase Count': userConfiguration.properties.purchase_count,
        'List Count': userConfiguration.properties.list_count,
        'Sale Count': userConfiguration.properties.sales_count,
        'Purchase Month': userConfiguration.properties.purchase_mon,
        'Purchase Sport': userConfiguration.properties.purchase_sport,
        'List Month': userConfiguration.properties.list_mon,
        'Join Date': userConfiguration.properties.join_date,
        Username: user.username,
      });
    } catch (e) {
      window.hj('identify', user.external_id, {
        Username: user.username,
      });
    }
  }

  getSentry()?.setUser(identifySentryUser(user));
}

export const trackingUnidentify = () => {
  window.amplitude?.getInstance().clearUserProperties();
};

export function isFirstVisit() {
  const hasVisited = getKnownCookie(null, 'sls_fv');
  if (!hasVisited) {
    function setCookie() {
      setKnownCookie(null, 'sls_fv', '1', {
        expires: addHours(new Date(), 18),
      });
    }

    addEventListener('visibilitychange', setCookie, {
      once: true,
    });
    addEventListener('beforeunload', setCookie, {
      once: true,
    });
    addEventListener('popstate', setCookie, {
      once: true,
    });

    setTimeout(setCookie, 1500);
  }

  // if you have not yet visited, then it's your first visit (1)

  if (hasVisited) {
    return '0';
  } else {
    return '1';
  }
}
