import { getEnv } from 'common-nextjs';
import fireGtagEvent from '~/services/analytics/fireGtagEvent';

export default function analyticsInitialize() {
  fireGtagEvent('js', new Date());

  if (getEnv('GA_TRACKING_ID')) {
    fireGtagEvent('config', getEnv('GA_TRACKING_ID'), {
      send_page_view: false,
    });
  }

  if (getEnv('GA4_TRACKING_ID')) {
    fireGtagEvent('config', getEnv('GA4_TRACKING_ID'), {
      send_page_view: false,
    });
  }
}
