var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"HoxESHp-pY31O7C1JiWWs"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { getEnv } from 'common-nextjs';

// const allowUrls = [/https?:\/\/.*\.?sidelineswap\.com/i];

const ignoreErrors = [
  // Random plugins/extensions
  'top.GLOBALS',
  // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
  'originalCreateNotification',
  'canvas.contentDocument',
  'MyApp_RemoveAllHighlights',
  'http://tt.epicplay.com',
  "Can't find variable: ZiteReader",
  'jigsaw is not defined',
  'ComboSearch is not defined',
  'http://loading.retry.widdit.com/',
  'atomicFindClose',
  // Facebook borked
  'fb_xd_fragment',
  // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
  // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
  'bmi_SafeAddOnload',
  'EBCallBackMessageReceived',
  // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
  'conduitPage',
  // Generic error code from errors outside the security sandbox
  // You can delete this if using raven.js > 1.0, which ignores these automatically.
  'Script error.',

  // Added by us to stop people's bad connections creating noise
  'Failed to fetch',
  'The network connection was lost.',

  // When we get to a better PWA state, bring these back
  'The request timed out.',
  'cancelled',
  'Network request failed',
  'The Internet connection appears to be offline.',
  'Preflight response is not successful',

  "Failed to execute 'postMessage' on 'Window'",
  'androidInterface',
  'Blocked a frame with origin',
  'ampproject',
  'ResizeObserver',
  /window\.top\.document/,
  /Illegal invocation/i,
  /Tapad/,
  /Redirected/,
  /pageFold/,
  /_AutofillCallbackHandler/,
];

const denyUrls = [
  // Facebook flakiness
  /graph\.facebook\.com/i,
  // Facebook blocked
  /connect\.facebook\.net\/en_US\/all\.js/i,
  // Woopra flakiness
  /eatdifferent\.com\.woopra-ns\.com/i,
  /static\.woopra\.com\/js\/woopra\.js/i,
  // Chrome extensions
  /extensions\//i,
  /^chrome:\/\//i,
  // Other plugins
  /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
  /webappstoolbarba\.texthelp\.com\//i,
  /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  // Ignore Google flakiness
  /\/(gtm|ga|analytics)\.js/i,
  /intercom/i,
  /yahoo\.com/i,
  /pub\.network/i,
  /freestar/i,
  /static\.criteo\.net\/js\/connect\.js/i,
  /static\.yieldmo\.com/i,
  /ajs-assets\.ftstatic\.com/i,
  /cdn\.flashtalking\.com/i,
];

Sentry.init({
  ignoreErrors,
  // allowUrls: [/https?:\/\/.*\.?sidelineswap\.com/i],
  denyUrls,
  dsn:
    // process.env.SENTRY_DSN ||
    // process.env.NEXT_PUBLIC_SENTRY_DSN ||
    'https://9a37bc078bbd429ab7e930cf277e022b@o28201.ingest.us.sentry.io/1512457',
  tracesSampleRate: 0.2,
  environment: getEnv(),
  /*beforeSend: (event, hint) => {
    // Only log errors for logged in users
    // if (document.cookie.includes('access_token')) {
    //   return event;
    // } else {
    //   return null;
    // }

    console.log('Sending event to Sentry', event, hint);

    return event;
  },*/
  // integrations: [
  //   Sentry.rewriteFramesIntegration({
  //     iteratee: frame => {
  //       frame.filename = frame.filename?.replace('~/_next', '/web/_next');
  //       return frame;
  //     },
  //   }),
  // ],
});
