import {
  ApiPaginatedResponse,
  FetcherArgs,
  Session,
  Uuid,
  fetcher,
  fetcherSession,
  getBaseUrl,
  makeFetcher,
} from 'fetcher-session';
import {
  CommerceBalance,
  CommerceBankAccount,
  CommerceBankFieldsResponse,
  CommercePayeeDetails,
  CommercePayeeDetailsRequest,
  CommercePayeeDetailsUpdateRequest,
  CommerceTaxForm,
  CommerceTransferMethod,
  ExchangeRateResponse,
  RoutingNumberLookup,
  RoutingNumberLookupFailed,
} from '~/typings/services/commerce/sellerAccount';
import {
  RailsSellerAccountStats,
  RailsTransaction,
} from '~/typings/services/rails/sellerAccount';
import { RailsSellerAccount } from '~/typings/services/rails/session';

export const fetchCashoutSaleTransactions = makeFetcher<
  ApiPaginatedResponse<RailsTransaction[]>
>(
  (
    typeFilters: RailsTransaction['type'][],
    page: number | string | undefined = 1,
  ) => [
    '/v1/transactions',
    {
      dataOnly: false,
      params: {
        page,
        type: typeFilters,
      },
    },
  ],
);

export const fetchReferralCode = makeFetcher<{ referral_code: string }>(
  () =>
    ({ uuid }) =>
      `/iam/v1/auth/user/${uuid}/referral_code`,
);

export const fetchSellerAccountStats = makeFetcher<RailsSellerAccountStats>(
  () =>
    ({ uuid }) =>
      `/v1/users/${uuid}/seller_account/stats`,
);

export const fetchRailsLegacySellerAccount = makeFetcher<RailsSellerAccount>(
  () =>
    ({ uuid }) =>
      `/v1/users/${uuid}/seller_account`,
);

export const fetchSecurePayeeDetails = makeFetcher<CommercePayeeDetails>(() => [
  ({ uuid }) => `/commerce/external/v1/payee/${uuid}`,
  {
    baseUrl: getBaseUrl('secureVpc'),
  },
]);

export const fetchRequiredBankAccountFields = makeFetcher<
  CommerceBankFieldsResponse,
  [string, string, 'company' | 'personal']
>((country: string, currency: string, type: 'company' | 'personal') => [
  '/commerce/external/v1/meta/bank_fields',
  {
    dataOnly: false,
    baseUrl: getBaseUrl('secureVpc'),
    params: {
      country,
      currency,
      type,
    },
  },
]);

export const postRegisterPayee = (values: CommercePayeeDetailsRequest) =>
  fetcher<CommerceBankFieldsResponse>('/commerce/external/v1/payee', {
    baseUrl: getBaseUrl('secureVpc'),
    body: values,
    method: 'post',
  });

export const submitCashout = makeFetcher<CommerceBankFieldsResponse, [number]>(
  (cashoutAmount: number) => [
    '/commerce/account/v1/cashouts',
    {
      body: {
        cashout_processor: 'PAYONEER' as CommerceTransferMethod['type'],
        amount: String(cashoutAmount),
      },
      method: 'post',
    },
  ],
);

export const submitGiftCardCashout = (
  cashoutAmount: number,
  id: Uuid,
  email: string,
) =>
  fetcher<CommerceBankFieldsResponse>('/commerce/account/v1/cashouts', {
    body: {
      email,
      amount: String(cashoutAmount),
      cashout_processor: 'GIFT_CARD' as CommerceTransferMethod['type'],
      transfer_method_id: id,
    },
    method: 'post',
  });

export const submitVenmoCashout = (cashoutAmount: number) =>
  fetcher<CommerceBankFieldsResponse>('/commerce/account/v1/cashouts', {
    body: {
      amount: String(cashoutAmount),
      cashout_processor: 'VENMO' as CommerceTransferMethod['type'],
    },
    method: 'post',
  });

export const dobLookup = makeFetcher<
  {
    day: number;
    month: number;
    year: number;
  } | null,
  []
>(
  () =>
    ({ uuid }) =>
      `/commerce/account/v1/customers/${uuid}/dob_lookup`,
);

export const updateDob = makeFetcher<
  unknown,
  [day: number, month: number, year: number]
>((day: number, month: number, year: number) => [
  ({ uuid }) => `/commerce/external/v1/payee/${uuid}/dob`,
  {
    body: { day, month, year },
    baseUrl: getBaseUrl('secureVpc'),
    method: 'put',
  },
]);

export const updatePayee = (values: CommercePayeeDetailsUpdateRequest) =>
  fetcher(({ uuid }) => `/commerce/external/v1/payee/${uuid}/profile`, {
    body: values,
    baseUrl: getBaseUrl('secureVpc'),
    method: 'put',
  });

export const updateTransferMethod = (bank: CommerceBankAccount) =>
  fetcher<unknown>(
    ({ uuid }) => `/commerce/external/v1/payee/${uuid}/payout_methods`,
    {
      body: { bank },
      baseUrl: getBaseUrl('secureVpc'),
      method: 'put',
    },
  );

export const routingNumberCompletion = makeFetcher<
  RoutingNumberLookupFailed | RoutingNumberLookup,
  [string]
>((routingNumber: string) => [
  '/commerce/external/v1/meta/rn_lookup',
  {
    baseUrl: getBaseUrl('secureVpc'),
    dataOnly: false,
    params: {
      rn: routingNumber,
    },
  },
]);

export const fetchExchangeRate = makeFetcher<ExchangeRateResponse, [string]>(
  (destinationCurrency: string) => [
    '/commerce/external/v1/meta/exchange_rate',
    {
      baseUrl: getBaseUrl('secureVpc'),
      dataOnly: false,
      params: {
        currency: destinationCurrency,
      },
    },
  ],
);

export const cancelCashouts = makeFetcher<unknown, []>(() => [
  ({ uuid }) => `/commerce/account/v1/customers/${uuid}/cancel_pending`,
  {
    method: 'post',
  },
]);

export const fetchCashoutBalances = async (session?: Session) => {
  return await fetcherSession<{ balances: CommerceBalance[] }>(
    session,
    ({ uuid }) => `/commerce/account/v2/customers/${uuid}/balances`,
  );
};

export async function fetchSpendableBalance(this: any) {
  const { balances } = await fetcher.call<
    any,
    FetcherArgs,
    Promise<{ balances: CommerceBalance[] }>
  >(this, ({ uuid }) => `/commerce/account/v2/customers/${uuid}/balances`);

  return balances.find(b => b.type === 'TOTAL_SPENDABLE');
}

interface CustomerInfo {
  customer_id: Uuid;
  birth_year: number | null;
}

export const fetchCustomerInfo = () =>
  fetcher<CustomerInfo>(
    ({ uuid }) => `/commerce/account/v1/customers/${uuid}/info`,
  );

interface PutCustomerInfoBody {
  birth_year: number;
}

export const putCustomerInfo = (body: PutCustomerInfoBody) =>
  fetcher<CustomerInfo>(
    ({ uuid }) => `/commerce/account/v1/customers/${uuid}/info`,
    {
      method: 'put',
      body,
    },
  );

export const fetchW9RequestForm = () =>
  fetcher<{ form_url: string }>(
    ({ uuid }) => `/commerce/tax/v1/seller/${uuid}/w9/form_url`,
  );

export const deleteBankAccount = () =>
  fetcher(
    ({ uuid }) => `/commerce/account/v2/customers/${uuid}/bank_accounts`,
    {
      method: 'delete',
    },
  );

export const fetchSellerAccountForms = makeFetcher<
  CommerceTaxForm[],
  [year?: string]
>(year => [
  ({ uuid }) => `/commerce/tax/v1/seller/${uuid}/forms`,
  {
    params: {
      year,
    },
  },
]);

export const postDownloadForm = (id: string) =>
  fetcher(({ uuid }) => `/commerce/tax/v1/seller/${uuid}/forms/${id}`, {
    blobResponse: true,
  });

export const postScheduleSellerReport = () =>
  fetcher(({ uuid }) => `/v1/users/${uuid}/seller_account/sales_reports`, {
    method: 'post',
  });

export const postDownloadSalesReport = (id: string | number) =>
  fetcher(
    ({ uuid }) => `/v1/users/${uuid}/seller_account/sales_reports/${id}`,
    {
      blobResponse: true,
    },
  );

export const postDownloadItemExport = (id: string | number) =>
  fetcher(
    ({ uuid }) => `/v1/users/${uuid}/seller_account/items_reports/${id}`,
    {
      blobResponse: true,
    },
  );

export interface CommerceInformActResponse {
  email: string;
  phone?: string;
  bank_name?: string;
  bank_pan?: string;
  address_id?: string;
  document_required?: boolean;
  phone_type?: CommerceInformActForm['phone_type'];
  last_submitted_at?: string; // Date
  seller_type?: string;
  address_type?: CommerceInformActForm['address_type'];
  required_actions: string[];
}

export interface CommerceInformActForm {
  phone_type: 'PERSONAL' | 'WORK';
  address_id: string;
  address_type: 'RESIDENTIAL' | 'BUSINESS';
  document?: File;
}

export const fetchInformActFormData = (session?: Session) => {
  return fetcherSession<CommerceInformActResponse>(
    session,
    ({ uuid }) => `/commerce/account/v1/customers/${uuid}/inform_form_data`,
  );
};

export const postInformActForm = async (form: CommerceInformActForm) => {
  const formData = new FormData();
  formData.set('phone_type', form.phone_type);
  formData.set('address_id', form.address_id);
  formData.set('address_type', form.address_type);

  if (form.document) {
    formData.set('document', form.document);
  }

  return fetcher<CommerceInformActResponse>(
    ({ uuid }) => `/commerce/account/v1/customers/${uuid}/inform_form`,
    {
      method: 'post',
      body: formData,
      blob: true,
    },
  );
};
