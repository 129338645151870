import { GAPageType } from '~/services/analytics/AnalyticsInterfaces';
import fireFacebookEvent from '~/services/analytics/fireFacebookEvent';
import fireGtagEvent from '~/services/analytics/fireGtagEvent';
import { isFirstVisit } from '~/services/analytics/identify';

export default function analyticsPageView(url: string) {
  fireFacebookEvent('track', 'PageView');

  let pageType: GAPageType | undefined = undefined;

  if (url.startsWith('/blog')) {
    pageType = 'Blog';
  } else if (url.startsWith('/product')) {
    pageType = 'Focused Model PLA';
  } else if (url.startsWith('/locker')) {
    pageType = 'Locker';
  } else if (url == '/') {
    pageType = 'Home Page';
  }

  fireGtagEvent('event', 'page_view', {
    page_type: pageType,
    first_visit: isFirstVisit(),
  });
}
